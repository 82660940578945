/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { isNebulaRegularUserAccess } from '@app/core/ngrx';
import { AppState } from '@app/core/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RegularNebulaUserGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  canActivate(): Observable<true | UrlTree> {
    return this.store
      .select(isNebulaRegularUserAccess)
      .pipe(map((isRegularNebulaUser) => (isRegularNebulaUser ? this.router.parseUrl('/clusters/list') : true)));
  }
}
